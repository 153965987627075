import { Api } from '@b2c-web-marketing/shared/storyblok-sdk';

import { SharedseoschemaItems } from '../types/basic';
import { SharedseoschemaItemsDocument } from '../types/hooks';
import { SharedseoschemaItemsQuery, SharedseoschemaItemsQueryVariables } from '../types/operations';

export const getSharedSeoSchemaItems = async (
  variables: SharedseoschemaItemsQueryVariables
): Promise<SharedseoschemaItems> => {
  const { data } = await Api.getPageItems<
    SharedseoschemaItemsQuery,
    SharedseoschemaItemsQueryVariables
  >(SharedseoschemaItemsDocument, variables);
  return data?.SharedseoschemaItems as SharedseoschemaItems;
};
