import React from 'react';

export const BasketIcon = ({ theme }) => {
  const mode = theme === 'primary' ? '#FFFFFF' : '#000000';

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-button-icon"
      focusable="false"
    >
      <g clipPath="url(#clip0_49_959)">
        <path
          data-testid="cart-icon"
          d="M6.5 23.5C7.05228 23.5 7.5 23.0523 7.5 22.5C7.5 21.9477 7.05228 21.5 6.5 21.5C5.94772 21.5 5.5 21.9477 5.5 22.5C5.5 23.0523 5.94772 23.5 6.5 23.5Z"
          fill={mode}
        ></path>
        <path
          d="M20.5 23.5C21.0523 23.5 21.5 23.0523 21.5 22.5C21.5 21.9477 21.0523 21.5 20.5 21.5C19.9477 21.5 19.5 21.9477 19.5 22.5C19.5 23.0523 19.9477 23.5 20.5 23.5Z"
          fill={mode}
        ></path>
        <path
          d="M6.5 23.5C7.05228 23.5 7.5 23.0523 7.5 22.5C7.5 21.9477 7.05228 21.5 6.5 21.5C5.94772 21.5 5.5 21.9477 5.5 22.5C5.5 23.0523 5.94772 23.5 6.5 23.5Z"
          stroke={mode}
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M20.5 23.5C21.0523 23.5 21.5 23.0523 21.5 22.5C21.5 21.9477 21.0523 21.5 20.5 21.5C19.9477 21.5 19.5 21.9477 19.5 22.5C19.5 23.0523 19.9477 23.5 20.5 23.5Z"
          stroke={mode}
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M1.5 1.5H4.5V15.5C4.5 16.0304 4.71071 16.5391 5.08579 16.9142C5.46086 17.2893 5.96957 17.5 6.5 17.5H22.5"
          stroke={mode}
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M4.5 5.5H21.5L19.5 13.5H4.5"
          stroke={mode}
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_49_959">
          <rect width="24" height="24" fill="transparent"></rect>
        </clipPath>
      </defs>
    </svg>
  );
};
