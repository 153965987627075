import React from 'react';

import { BasketIcon } from 'apps/urban/public/assets/images/Basket';
import { BasketIcon2 } from 'apps/urban/public/assets/images/Basket2';
import Link from 'next/link';

import { HeaderBasketWrapper } from './HeaderBasket.style';

export const HeaderBasket = ({ basketData }) => {
  const { serviceCount, hasStartsAt, hasWorker } = basketData || {};

  const displayOneItem = serviceCount === 0 && (hasStartsAt || hasWorker);
  const hasAnyItem = serviceCount > 0 || displayOneItem;

  const numberOfItems = (n: number) => <span className="count">{n}</span>;

  return (
    <HeaderBasketWrapper hasItems={hasAnyItem}>
      <Link href="/booking-next/basket">
        <a className="link">
          {serviceCount > 0 && numberOfItems(serviceCount)}
          {displayOneItem && numberOfItems(1)}
          {hasAnyItem ? <BasketIcon2 theme="primary" /> : <BasketIcon theme />}
        </a>
      </Link>
    </HeaderBasketWrapper>
  );
};
