import styled from 'styled-components';

import { Header3, Body1 } from '../../Typography/Typography.style';

export const FooterHeroWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 5rem 0;

  ${({ theme }) => theme.mediaQuery.md} {
    border-bottom: 1px solid ${({ theme }) => theme.colors.whiteLightTransparent};
  }

  ${({ theme }) => theme.mediaQuery.xl} {
    padding: 7rem 0;
  }
`;

export const FooterHeroTitle = styled(Header3)`
  margin-bottom: 1rem;
  max-width: 74.2rem;
`;

export const FooterHeroDescription = styled(Body1)`
  margin-bottom: 3rem;
`;
