import React from 'react';

import { FooterContainer, FooterWrapper } from './Footer.style';
import { TFooterProps } from './Footer.types';
import { FooterCopyright } from './FooterCopyright/FooterCopyright';
import { FooterHero } from './FooterHero/FooterHero';
import { FooterNavigations } from './FooterNavigations/FooterNavigations';

export const Footer = ({
  buttonText,
  buttonUrl,
  description,
  localization,
  logo,
  navigations,
  socials,
  title,
  openLanguageModal,
}: TFooterProps) => {
  return (
    <FooterWrapper>
      <FooterContainer>
        <FooterHero
          title={title}
          description={description}
          buttonText={buttonText}
          buttonUrl={buttonUrl}
        />
        <FooterNavigations navigations={navigations} />
        <FooterCopyright
          logo={logo}
          localization={localization}
          socials={socials}
          openLanguageModal={openLanguageModal}
        />
      </FooterContainer>
    </FooterWrapper>
  );
};
