import { useRouter } from 'next/router';

import { Modal } from '../Modal/Modal';
import {
  LanguageSwitchHeading,
  LanguageSwitchButton,
  LanguageModalWrapper,
  LanguageSwitchModalContainer,
} from './LanguageSwitchModal.style';
import { LocaleCodes, TLanguageSwitchModalProps } from './LanguageSwitchModal.types';

export const LanguageSwitchModal = ({
  isLanguageModalOpen,
  openLanguageModal,
  closeLanguageModal,
  heading,
  unitedKingdomText,
  franceInFrenchText,
}: TLanguageSwitchModalProps) => {
  const { asPath, push, locale, locales } = useRouter();

  const toggleModal = () => (isLanguageModalOpen ? closeLanguageModal() : openLanguageModal());

  const handleLocation = (location: string) => {
    push(`/${location}${asPath}`, undefined, { locale: false });
    closeLanguageModal();
  };

  const filteredLocales = locales.filter((localeItem) => localeItem !== 'default');

  const localeData = {
    [LocaleCodes.UnitedKingdom]: {
      text: unitedKingdomText,
    },
    [LocaleCodes.FranceInFrench]: {
      text: franceInFrenchText,
    },
  };

  return (
    <Modal isOpen={isLanguageModalOpen} toggleModal={toggleModal}>
      <LanguageSwitchModalContainer>
        <LanguageSwitchHeading align="center">{heading}</LanguageSwitchHeading>
        <LanguageModalWrapper>
          {filteredLocales.map((location) => (
            <LanguageSwitchButton
              key={location}
              onClick={() => handleLocation(location)}
              isActive={locale === location}
            >
              {localeData[location]?.text}
            </LanguageSwitchButton>
          ))}
        </LanguageModalWrapper>
      </LanguageSwitchModalContainer>
    </Modal>
  );
};
