import { Api } from '@b2c-web-marketing/shared/storyblok-sdk';

import { CookiebannerItem } from '../types/basic';
import { CookiebannerItemDocument } from '../types/hooks';
import { CookiebannerItemQuery, CookiebannerItemQueryVariables } from '../types/operations';

export const getCookieBanner = async (
  variables: CookiebannerItemQueryVariables
): Promise<CookiebannerItem> => {
  const { data } = await Api.getCookieBannerItem<
    CookiebannerItemQuery,
    CookiebannerItemQueryVariables
  >(CookiebannerItemDocument, variables);

  return data.CookiebannerItem;
};
