import { TypographyStoryblok } from '../../TypographyBlok/TypographyBlok.types';
import type { TNavigationItem } from '../HeaderV2.types';

export const transformNavLoginData = (
  userLogged: boolean,
  navData: TNavigationItem[],
  myAccountLinkText: TypographyStoryblok[]
) => {
  if (!userLogged) {
    return navData;
  }

  const [logInItem, ...rest] = navData;

  return [{ ...logInItem, title: myAccountLinkText }, ...rest];
};
