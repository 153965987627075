import React, { useState } from 'react';

import { FooterComponent, FootervComponent, HeaderComponent, HeadervComponent } from '../../api';
import { FooterV2 } from '../../components-v2/FooterV2/FooterV2';
import { HeaderV2 } from '../../components-v2/HeaderV2/HeaderV2';
import { CookieBanner } from '../CookieBanner/CookieBanner';
import { Footer } from '../Footer/Footer';
import { Header } from '../Header/Header';
import { LanguageSwitchModal } from '../LanguageSwitchModal/LanguageSwitchModal';
// import { LiveChat } from '../LiveChat/LiveChat';
import { LiveChat2025 } from '../LiveChat2025/LiveChat2025';
import { Seo } from '../Seo/Seo';
import { LayoutContainer, LayoutContainerV2 } from './Layout.styles';
import { TLayoutProps } from './Layout.types';

export const Layout = ({
  children,
  header,
  footer,
  metatags,
  locationModal,
  cookieBanner,
  salesforceChat,
  noFollow,
  noIndex,
  canonical,
  useV2Header,
  useV2Footer,
  keepTopMargin,
}: TLayoutProps) => {
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);

  const openLanguageModal = () => setIsLanguageModalOpen(true);
  const closeLanguageModal = () => setIsLanguageModalOpen(false);

  const { heading, unitedKingdomText, franceInFrenchText } = locationModal;

  const LayoutContainerComponent = keepTopMargin
    ? LayoutContainer
    : useV2Header
    ? LayoutContainerV2
    : LayoutContainer;

  return (
    <LayoutContainerComponent>
      <Seo metatags={metatags} noFollow={noFollow} noIndex={noIndex} canonical={canonical} />
      <LanguageSwitchModal
        isLanguageModalOpen={isLanguageModalOpen}
        openLanguageModal={openLanguageModal}
        closeLanguageModal={closeLanguageModal}
        heading={heading}
        unitedKingdomText={unitedKingdomText}
        franceInFrenchText={franceInFrenchText}
      />
      {useV2Header ? (
        <HeaderV2 {...(header as HeadervComponent)} />
      ) : (
        <Header {...(header as HeaderComponent)} openLanguageModal={openLanguageModal} />
      )}
      {/* {salesforceChat && <LiveChat {...salesforceChat} />} */}
      {salesforceChat && <LiveChat2025 />}
      {children}
      {useV2Footer ? (
        <FooterV2 {...(footer as FootervComponent)} openLanguageModal={openLanguageModal} />
      ) : (
        <Footer {...(footer as FooterComponent)} openLanguageModal={openLanguageModal} />
      )}
      {cookieBanner && <CookieBanner {...cookieBanner} />}
    </LayoutContainerComponent>
  );
};
