import React, { useState } from 'react';

import { useBasketStatusContext } from 'apps/urban/context/basket';
import { useLoginProviderContext } from 'apps/urban/context/login';
import { useRouter } from 'next/router';

import {
  HeaderWrapper,
  HeaderNavMobile,
  HeaderNavDesktop,
  HeaderNavBurgerMobile,
  HeaderLogoSwitchWrapper,
} from './Header.style';
import { THeaderProps } from './Header.types';
import { HeaderBurger } from './HeaderBurger/HeaderBurger';
import { HeaderLanguageSwitch } from './HeaderLanguageSwitch/HeaderLanguageSwitch';
import { HeaderLogo } from './HeaderLogo/HeaderLogo';
import { HeaderMobile } from './HeaderMobile/HeaderMobile';
import { HeaderNav } from './HeaderNav/HeaderNav';
import { transformNavLoginData } from './util/transform';

export const Header = ({
  logo,
  openLanguageModal,
  locationFlagIcon,
  navigation,
  navigationLogin,
}: THeaderProps) => {
  const [open, setOpen] = useState(false);
  const router = useRouter();
  const isLoggedIn = useLoginProviderContext();
  const basketContext = useBasketStatusContext();

  const transformedData = transformNavLoginData(isLoggedIn, navigationLogin, router?.locale);

  return (
    <HeaderWrapper>
      <HeaderNavDesktop>
        <HeaderLogoSwitchWrapper>
          <HeaderLogo logo={logo} />
          {locationFlagIcon?.filename && (
            <HeaderLanguageSwitch
              locationFlagIcon={locationFlagIcon}
              openLanguageModal={openLanguageModal}
            />
          )}
        </HeaderLogoSwitchWrapper>
        <HeaderNav navigation={navigation} setOpen={setOpen} />
        <HeaderNav
          basketData={basketContext?.data}
          showBasket
          navigation={transformedData}
          setOpen={setOpen}
        />
      </HeaderNavDesktop>
      <HeaderNavMobile>
        <HeaderLogoSwitchWrapper>
          <HeaderLogo logo={logo} />
          {locationFlagIcon?.filename && (
            <HeaderLanguageSwitch
              locationFlagIcon={locationFlagIcon}
              openLanguageModal={openLanguageModal}
            />
          )}
        </HeaderLogoSwitchWrapper>
        <HeaderNavBurgerMobile>
          <HeaderNav
            basketData={basketContext?.data}
            showBasket
            showInitials={isLoggedIn}
            navigation={[]}
          />
          <HeaderBurger open={open} setOpen={setOpen} />
        </HeaderNavBurgerMobile>
      </HeaderNavMobile>
      <HeaderMobile
        open={open}
        navigation={
          isLoggedIn
            ? navigation.concat(navigationLogin.slice(1))
            : navigation.concat(navigationLogin)
        }
        setOpen={setOpen}
      />
    </HeaderWrapper>
  );
};
