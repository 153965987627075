export enum LocaleCodes {
  UnitedKingdom = 'en-gb',
  FranceInFrench = 'fr-fr',
}

export type TLanguageSwitchModalProps = {
  isLanguageModalOpen: boolean;
  openLanguageModal: () => void;
  closeLanguageModal: () => void;
  heading: string;
  unitedKingdomText: string;
  franceInFrenchText: string;
};
