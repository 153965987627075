import styled, { css } from 'styled-components';

import { THeaderWrapperStyled } from './HeaderV2.types';
import { HeaderMobileItem } from './HeaderV2Mobile/HeaderV2Mobile.style';

export const HeaderWrapper = styled.header<THeaderWrapperStyled>`
  background-color: ${({ theme, hasBackground }) =>
    hasBackground ? theme.colors.white : 'transparent'};
  transition: background-color 200ms;
  border-bottom: ${({ theme, hasBackground }) =>
    hasBackground ? `1px solid ${theme.colors.lightGray}` : 'none'};
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: ${({ theme }) => theme.customZIndex.secondary};

  :has(.header-v2-nav-item-link-wrapper:hover) {
    background-color: ${({ theme }) => theme.colors.white};
    border-bottom: none;

    .header-v2-nav-item {
      color: ${({ theme }) => theme.colors.black};
    }
  }
`;

export const HeaderContainer = css`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 1.4rem 0.5rem 1.4rem 2.4rem;
  width: 100%;
  height: 8rem;

  ${({ theme }) => theme.mediaQuery.xl} {
    padding: 0 10rem;
  }
`;

export const HeaderNavDesktop = styled.div`
  display: none;

  ${({ theme }) => theme.mediaQuery.xl} {
    display: flex;
    ${HeaderContainer}
  }
`;

export const HeaderNavMobile = styled.div`
  ${HeaderContainer}

  ${({ theme }) => theme.mediaQuery.xl} {
    display: none;
  }

  span {
    font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
    font-size: ${({ theme }) => theme.fonts.size.desktop.xxs};
    line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.xxs};
    margin: 0 2.4rem;
  }
`;

export const HeaderNavBurgerMobile = styled.div`
  align-items: center;
  display: flex;
`;

export const HeaderLogoSwitchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 1.4rem;
`;

export const HeaderSelectedMobileNavigationWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderSelectedMobileNavigationTitle = styled(HeaderMobileItem)`
  margin-left: 1.2rem;

  p {
    font-size: ${({ theme }) => theme.fonts.size.mobile.xs};
    font-weight: ${({ theme }) => theme.fonts.weight.regular};
    line-height: ${({ theme }) => theme.fonts.lineHeight.mobile.s};
    font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
    color: ${({ color, theme }) => color || theme.colors.black};
  }
`;
