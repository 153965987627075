import { Api } from '@b2c-web-marketing/shared/storyblok-sdk';

import { SalesforcechatItem } from '../types/basic';
import { SalesforceChatItemDocument } from '../types/hooks';
import { SalesforceChatItemQuery, SalesforceChatItemQueryVariables } from '../types/operations';

export const getSalesforceChat = async (
  variables: SalesforceChatItemQueryVariables
): Promise<SalesforcechatItem> => {
  const { data } = await Api.getHeaderItem<
    SalesforceChatItemQuery,
    SalesforceChatItemQueryVariables
  >(SalesforceChatItemDocument, variables);

  return data.SalesforcechatItem;
};
