import React from 'react';

import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';

import { TSeoProps } from './Seo.types';

export const Seo = ({ metatags, noFollow, noIndex, canonical }: TSeoProps) => {
  const { asPath, locale } = useRouter();

  const canonicalUrl = canonical ? canonical : `https://urban.co/${locale}${asPath}`;

  return (
    <NextSeo
      title={metatags?.title}
      description={metatags?.description}
      nofollow={noFollow}
      noindex={noIndex}
      canonical={canonicalUrl}
      additionalLinkTags={[
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '16x16',
          href: '/assets/images/favicon_16x16.png',
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: '/assets/images/favicon_32x32.png',
        },
      ]}
      openGraph={{
        title: metatags?.og_title,
        description: metatags?.og_description,
        images: [
          {
            url: metatags?.og_image,
          },
        ],
      }}
    />
  );
};
