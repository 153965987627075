import React from 'react';

import { THeaderMobileProps } from '../Header.types';
import { HeaderNav } from '../HeaderNav/HeaderNav';
import { HeaderMobileWrapper } from './HeaderMobile.style';

export const HeaderMobile = ({ open, navigation, setOpen }: THeaderMobileProps) => {
  return (
    <HeaderMobileWrapper open={open}>
      <HeaderNav navigation={navigation} setOpen={setOpen} />
    </HeaderMobileWrapper>
  );
};
