import { Api } from '@b2c-web-marketing/shared/storyblok-sdk';

import { FooterItem } from '../types/basic';
import { FooterItemDocument } from '../types/hooks';
import { FooterItemQuery, FooterItemQueryVariables } from '../types/operations';

export const getFooter = async (variables: FooterItemQueryVariables): Promise<FooterItem> => {
  const { data } = await Api.getFooterItem<FooterItemQuery, FooterItemQueryVariables>(
    FooterItemDocument,
    variables
  );

  return data.FooterItem;
};
