import React, { useEffect, useLayoutEffect } from 'react';

import getConfig from 'next/config';

import { injectCss } from '../../utils/injectCss';

const config = getConfig();

const API_BASE = config?.publicRuntimeConfig?.NEXT_PUBLIC_API_BASE;

// Both development and staging run the staging api base
const isProduction = API_BASE && !API_BASE.includes('staging');

const CHAT_CONFIG = {
  staging: {
    CHAT_SCRIPT_URL:
      'https://urbanco--partialcpy.sandbox.my.site.com/ESWMessagingUserWebsite1735010539095/assets/js/bootstrap.min.js',
    CHAT_ENVIRONMENT_ID: '00DUE000001FwG7',
    CHAT_PLATFORM_ID: 'Messaging_User_Website_Client',
    CHAT_DEPLOYMENT_URL:
      'https://urbanco--partialcpy.sandbox.my.site.com/ESWMessagingUserWebsite1735010539095',
    CHAT_SRC_URL: 'https://urbanco--partialcpy.sandbox.my.salesforce-scrt.com',
  },
  production: {
    CHAT_SCRIPT_URL:
      'https://urbanco.my.site.com/ESWCustomerWebsite1738086514019/assets/js/bootstrap.min.js',
    CHAT_ENVIRONMENT_ID: '00D0N000000gJ18',
    CHAT_PLATFORM_ID: 'Customer_Website',
    CHAT_DEPLOYMENT_URL: 'https://urbanco.my.site.com/ESWCustomerWebsite1738086514019',
    CHAT_SRC_URL: 'https://urbanco.my.salesforce-scrt.com',
  },
};

const CHAT_ENVIRONMENT_CONFIG = isProduction ? CHAT_CONFIG.production : CHAT_CONFIG.staging;

export const LiveChat2025 = () => {
  useLayoutEffect(() => {
    /* LiveChat overrides to make it full screen on mobile */
    injectCss(`
      @media only screen and (min-width: 15em) and (max-width: 47.9375em) {
        .embedded-messaging > .embeddedMessagingFrame[class~="isMaximized"] {
          margin: 0 !important;
          max-height: unset !important;
          max-width: unset !important;
          z-index: 1300 !important;
        }
      }
    `);
  }, []);

  useEffect(() => {
    try {
      window.addEventListener('onEmbeddedMessagingReady', () => {
        const pageURL = window.location.href; // Use this for the full URL
        window.embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields({
          Platform: 'CLIENT WEBSITE',
          // UMUserID: `${userId}`,
          Location: pageURL,
        });
        // Lets populate the Images used for the Messaging Chat branding with our static resources
        const brandings = window.embeddedservice_bootstrap.settings.embeddedServiceConfig.branding;
        for (let i = 0; i < brandings.length; i++) {
          if (brandings[i].n === 'logoImage') {
            brandings[i].v =
              'https://res.cloudinary.com/huxr6hrje/image/upload/v1734957989/chat-2025/UM_Chat_Logo.png';
          } else if (brandings[i].n === 'avatarImage') {
            brandings[i].v =
              'https://res.cloudinary.com/huxr6hrje/image/upload/v1734957989/chat-2025/UM_Agent_Neutral.png';
          } else if (brandings[i].n === 'botImage') {
            brandings[i].v =
              'https://res.cloudinary.com/huxr6hrje/image/upload/v1738687559/chat-2025/prod/botimage1.png';
          }
        }
      });

      window.embeddedservice_bootstrap.settings.language = 'en_US'; // For example, enter 'en' or 'en-US'

      window.embeddedservice_bootstrap.init(
        CHAT_ENVIRONMENT_CONFIG.CHAT_ENVIRONMENT_ID,
        CHAT_ENVIRONMENT_CONFIG.CHAT_PLATFORM_ID,
        CHAT_ENVIRONMENT_CONFIG.CHAT_DEPLOYMENT_URL,
        {
          scrt2URL: CHAT_ENVIRONMENT_CONFIG.CHAT_SRC_URL,
        }
      );
    } catch (err) {
      console.error('Error loading Embedded Messaging: ', err);
    }
  }, []);

  return <div />;
};
