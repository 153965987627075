import { StoryblokComponent } from '@storyblok/react';
import React from 'react';

import Link from 'next/link';

import { ReactComponent as Arrow } from '../../../public/assets/images/arrow-tab.svg';
import { THeaderV2DropdownProps } from '../HeaderV2.types';
import {
  HeaderV2DropdownColumnItem,
  HeaderV2DropdownColumnItemLink,
  HeaderV2DropdownColumnTitle,
  HeaderV2DropdownColumnViewAllItem,
  HeaderV2DropdownColumnWrapper,
  HeaderV2DropdownFooter,
  HeaderV2DropdownBody,
  HeaderV2DropdownWrapper,
  HeaderV2DropdownFooterLink,
  HeaderV2DropdownFooterLinkTitle,
  HeaderV2DropdownColumnViewAllLink,
} from './HeaderV2Dropdown.style';

const DEFAULT_COLUMN_LINK_TITLE = 'View All';
const DEFAULT_FOOTER_LINK_TITLE = 'Apply as a therapist';

export const HeaderV2Dropdown = ({ navigationItem, footer }: THeaderV2DropdownProps) => {
  return (
    <HeaderV2DropdownWrapper className="header-v2-dropdown">
      <HeaderV2DropdownBody>
        {navigationItem.columns.map((column) => (
          <HeaderV2DropdownColumnWrapper key={column._uid}>
            <HeaderV2DropdownColumnTitle>
              <StoryblokComponent blok={column.title[0]} />
            </HeaderV2DropdownColumnTitle>
            {column.items.map((columnItem) => (
              <Link
                prefetch={false}
                key={columnItem._uid}
                href={
                  columnItem.item.cached_url.startsWith('http')
                    ? columnItem.item.cached_url
                    : `/${columnItem.item.cached_url}`
                }
                passHref
              >
                <HeaderV2DropdownColumnItemLink>
                  <HeaderV2DropdownColumnItem>
                    <StoryblokComponent blok={columnItem.title[0]} />
                  </HeaderV2DropdownColumnItem>
                </HeaderV2DropdownColumnItemLink>
              </Link>
            ))}
            <Link
              prefetch={false}
              href={
                column.item.cached_url.startsWith('http')
                  ? column.item.cached_url
                  : `/${column.item.cached_url}`
              }
              passHref
            >
              <HeaderV2DropdownColumnViewAllLink>
                <HeaderV2DropdownColumnViewAllItem>
                  {column.linkTitle[0] ? (
                    <StoryblokComponent blok={column.linkTitle[0]} />
                  ) : (
                    DEFAULT_COLUMN_LINK_TITLE
                  )}
                </HeaderV2DropdownColumnViewAllItem>
              </HeaderV2DropdownColumnViewAllLink>
            </Link>
          </HeaderV2DropdownColumnWrapper>
        ))}
      </HeaderV2DropdownBody>
      <HeaderV2DropdownFooter>
        <Link
          prefetch={false}
          href={
            footer.link.cached_url.startsWith('http')
              ? footer.link.cached_url
              : `/${footer.link.cached_url}`
          }
          passHref
        >
          <HeaderV2DropdownFooterLink>
            <HeaderV2DropdownFooterLinkTitle>
              {footer.linkTitle[0] ? (
                <StoryblokComponent blok={footer.linkTitle[0]} />
              ) : (
                DEFAULT_FOOTER_LINK_TITLE
              )}
            </HeaderV2DropdownFooterLinkTitle>
            <Arrow />
          </HeaderV2DropdownFooterLink>
        </Link>
      </HeaderV2DropdownFooter>
    </HeaderV2DropdownWrapper>
  );
};
