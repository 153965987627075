import { Api } from '@b2c-web-marketing/shared/storyblok-sdk';

import { TreatmentItem } from '../types/basic';
import { TreatmentItemDocument } from '../types/hooks';
import { TreatmentItemQuery, TreatmentItemQueryVariables } from '../types/operations';

export const getTreatment = async (
  variables: TreatmentItemQueryVariables
): Promise<TreatmentItem> => {
  const { data } = await Api.getTreatmentItem<TreatmentItemQuery, TreatmentItemQueryVariables>(
    TreatmentItemDocument,
    variables
  );
  // @ts-ignore
  return data.TreatmentItem;
};
