import styled from 'styled-components';

export const HeaderBasketWrapper = styled.div<{ hasItems: boolean }>`
  background: ${(props) => props.hasItems && 'rgb(249, 91, 69)'};
  padding: ${(props) => (props.hasItems ? '0.4rem 1rem' : '0')};
  border-radius: 60px;

  &:hover {
    opacity: 0.8;
    transition: opacity 0.3s ease-in-out 0s;
  }

  .link {
    font-family: 'Roboto, sans-serif';
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    color: rgb(255, 255, 255);

    svg {
      margin: ${(props) => (props.hasItems ? '0px 0.3rem 0px 1rem' : '0 16px 0 16px')};
      line-height: 1;
      stroke: red;
      width: ${(props) => (props.hasItems ? '18px' : '24px')};
      height: ${(props) => (props.hasItems ? '18px' : '24px')};
    }

    .count {
      margin: 0;
      font-family: Roboto;
      font-size: 12px;
    }
  }
`;
