import { Api } from '@b2c-web-marketing/shared/storyblok-sdk';

import { LocationmodalItem } from '../types/basic';
import { LocationModalItemDocument } from '../types/hooks';
import { LocationModalItemQuery, LocationModalItemQueryVariables } from '../types/operations';

export const getLocationModal = async (
  variables: LocationModalItemQueryVariables
): Promise<LocationmodalItem> => {
  const { data } = await Api.getHeaderItem<LocationModalItemQuery, LocationModalItemQueryVariables>(
    LocationModalItemDocument,
    variables
  );

  return data.LocationmodalItem;
};
