import styled, { css } from 'styled-components';

export const HeaderWrapper = styled.header`
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: ${({ theme }) => theme.customZIndex.secondary};
`;

export const HeaderContainer = css`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 1.4rem 2.4rem;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.xl} {
    padding: 2.6rem 2.4rem;
  }
`;

export const HeaderNavDesktop = styled.div`
  display: none;

  ${({ theme }) => theme.mediaQuery.xl} {
    display: flex;
    ${HeaderContainer}
  }
`;

export const HeaderNavMobile = styled.div`
  ${HeaderContainer}

  ${({ theme }) => theme.mediaQuery.xl} {
    display: none;
  }

  span {
    font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
    font-size: ${({ theme }) => theme.fonts.size.desktop.xxs};
    line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.xxs};
    margin: 0 2.4rem;
  }
`;

export const HeaderNavBurgerMobile = styled.div`
  align-items: center;
  display: flex;
`;

export const HeaderLogoSwitchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 1.4rem;
`;
