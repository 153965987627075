import styled from 'styled-components';

export const FooterWrapper = styled.footer`
  background-color: ${({ theme }) => theme.colors.ebonyClay};
  padding: 0 2.4rem;
`;

export const FooterContainer = styled.div`
  margin: 0 auto;
  max-width: ${({ theme }) => theme.layout.container};
  width: 100%;
`;
