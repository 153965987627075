import { Api } from '@b2c-web-marketing/shared/storyblok-sdk';

import { BlogcategoryItem } from '../types/basic';
import { BlogCategoryItemDocument } from '../types/hooks';
import { BlogCategoryItemQuery, BlogCategoryItemQueryVariables } from '../types/operations';

export const getBlogCategory = async (
  variables: BlogCategoryItemQueryVariables
): Promise<BlogcategoryItem> => {
  const { data } = await Api.getPageItem<BlogCategoryItemQuery, BlogCategoryItemQueryVariables>(
    BlogCategoryItemDocument,
    variables
  );
  return data.BlogcategoryItem as BlogcategoryItem;
};
