import styled from 'styled-components';

import { Nav1 } from '../../../components/Typography/Typography.style';

export const HeaderV2DropdownWrapper = styled.div`
  cursor: default;
  padding-top: 4rem;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 8rem;
  left: 0;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.04);
  transition: transform 200ms 200ms, opacity 200ms 200ms;
  display: flex;
  transform: scaleY(0);
  transform-origin: top;
  opacity: 0;
`;

export const HeaderV2DropdownBody = styled.div`
  display: flex;
  column-gap: 12rem;
`;

export const HeaderV2DropdownColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.8rem;
`;

export const HeaderV2DropdownColumnTitle = styled(Nav1)`
  p {
    font-size: ${({ theme }) => theme.fonts.size.desktop.xxs};
    font-weight: ${({ theme }) => theme.fonts.weight.regular};
    line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.xxs};
    color: rgba(0, 0, 0, 0.5);
  }
`;

export const HeaderV2DropdownColumnItemLink = styled.a``;

export const HeaderV2DropdownColumnItem = styled(Nav1)`
  display: block;
  cursor: pointer;

  p {
    font-size: ${({ theme }) => theme.fonts.size.desktop.xxs};
    font-weight: ${({ theme }) => theme.fonts.weight.regular};
    line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.xxs};
  }

  :hover {
    text-decoration: underline;
  }
`;

export const HeaderV2DropdownColumnViewAllLink = styled.a`
  margin-top: auto;
`;

export const HeaderV2DropdownColumnViewAllItem = styled(Nav1)`
  display: block;
  cursor: pointer;
  text-decoration: underline;

  p {
    font-size: ${({ theme }) => theme.fonts.size.desktop.xxs};
    font-weight: ${({ theme }) => theme.fonts.weight.regular};
    line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.xxs};
  }
`;

export const HeaderV2DropdownFooter = styled.div`
  padding-top: 6.5rem;
  padding-bottom: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderV2DropdownFooterLink = styled.a`
  display: flex;
  align-items: center;
`;

export const HeaderV2DropdownFooterLinkTitle = styled(Nav1)`
  text-decoration: underline;
  margin-right: 0.8rem;

  p {
    font-size: ${({ theme }) => theme.fonts.size.desktop.xxs};
    font-weight: ${({ theme }) => theme.fonts.weight.regular};
    line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.xxs};
  }
`;
