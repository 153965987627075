import React from 'react';

import { getColorFromStory } from '../../utils/getColorFromStory';
import { FooterCopyrightV2 } from './FooterCopyright/FooterCopyrightV2';
import { FooterHeroV2 } from './FooterHeroV2/FooterHeroV2';
import { FooterNavigationsV2 } from './FooterNavigationsV2/FooterNavigationsV2';
import { FooterContainer, FooterWrapper } from './FooterV2.style';
import { TFooterProps } from './FooterV2.types';

export const FooterV2 = ({
  description,
  localization,
  logo,
  navigations,
  socials,
  title,
  openLanguageModal,
  backgroundColor,
  ctaButton,
  divider,
}: TFooterProps) => {
  return (
    <FooterWrapper backgroundColor={backgroundColor ? getColorFromStory(backgroundColor) : null}>
      <FooterContainer>
        <FooterHeroV2
          title={title}
          description={description}
          ctaButton={ctaButton}
          divider={divider}
        />
        <FooterNavigationsV2 navigations={navigations} />
        <FooterCopyrightV2
          logo={logo}
          localization={localization}
          socials={socials}
          openLanguageModal={openLanguageModal}
        />
      </FooterContainer>
    </FooterWrapper>
  );
};
