import styled from 'styled-components';

export const FooterNavigationsWrapper = styled.div`
  display: flex;

  ${({ theme }) => theme.mediaQuery.md} {
    border-bottom: 1px solid ${({ theme }) => theme.colors.midGray};
    padding: 5rem 0;
  }
`;

export const FooterAccordionMobile = styled.div`
  display: block;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.md} {
    display: none;
  }
`;
