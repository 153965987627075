import styled from 'styled-components';

import { Body4 } from '../../Typography/Typography.style';

export const FooterCopyrightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 3rem 0;

  ${({ theme }) => theme.mediaQuery.md} {
    flex-direction: row;
  }
`;

export const FooterCopyrightLogo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;

  ${({ theme }) => theme.mediaQuery.md} {
    margin-bottom: 0;
  }
`;

export const FooterCopyrightLocalization = styled.button`
  align-items: center;
  display: flex;
  margin-left: 3.8rem;
  cursor: pointer;
  background: none;
  border: none;
`;

export const FooterCopyrightLocalizationText = styled(Body4)`
  margin-left: 1.2rem;
`;

export const FooterCopyrightSocial = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  a {
    margin-left: 2rem;
  }
`;
