import { StoryblokComponent } from '@storyblok/react';
import React from 'react';

import { colors } from '../../../styles/constants';
import { TFooterHeroProps } from '../FooterV2.types';
import { FooterHeroWrapper, FooterHeroTitle, FooterHeroDescription } from './FooterHeroV2.style';

export const FooterHeroV2 = ({ title, description, ctaButton, divider }: TFooterHeroProps) => {
  return (
    <FooterHeroWrapper>
      <FooterHeroTitle align="left" color={colors.black}>
        {title}
      </FooterHeroTitle>
      {!!divider.length && <StoryblokComponent blok={divider[0]} />}
      <FooterHeroDescription align="left" color={colors.black}>
        {description}
      </FooterHeroDescription>
      {!!ctaButton.length && <StoryblokComponent blok={ctaButton[0]} />}
    </FooterHeroWrapper>
  );
};
