import React from 'react';

import { Accordion } from '../../../components/Accordion/Accordion';
import { TNavigationsProps } from '../FooterV2.types';
import { FooterNavigationV2 } from './FooterNavigationV2/FooterNavigationV2';
import { FooterNavigationsWrapper, FooterAccordionMobile } from './FooterNavigationsV2.style';

export const FooterNavigationsV2 = ({ navigations }: TNavigationsProps) => (
  <FooterNavigationsWrapper>
    <>
      {navigations.map((navigation) => (
        <FooterNavigationV2 key={navigation._uid} {...navigation} />
      ))}
      <FooterAccordionMobile>
        <Accordion data={navigations} context="footerV2" />
      </FooterAccordionMobile>
    </>
  </FooterNavigationsWrapper>
);
