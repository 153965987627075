import { Api } from '@b2c-web-marketing/shared/storyblok-sdk';

import { LegalItem } from '../types/basic';
import { LegalItemDocument } from '../types/hooks';
import { LegalItemQuery, LegalItemQueryVariables } from '../types/operations';

export const getLegal = async (variables: LegalItemQueryVariables): Promise<LegalItem> => {
  const { data } = await Api.getPageItem<LegalItemQuery, LegalItemQueryVariables>(
    LegalItemDocument,
    variables
  );
  return data.LegalItem;
};
