import React from 'react';

import Image from 'next/image';
import Link from 'next/link';

import { colors } from '../../../styles/constants';
import { Logo } from '../../Logo/Logo';
import { TCopyrightsProps } from '../Footer.types';
import {
  FooterCopyrightWrapper,
  FooterCopyrightLocalization,
  FooterCopyrightSocial,
  FooterCopyrightLogo,
  FooterCopyrightLocalizationText,
} from './FooterCopyright.style';

export const FooterCopyright = ({
  logo,
  localization,
  socials,
  openLanguageModal,
}: TCopyrightsProps) => {
  return (
    <FooterCopyrightWrapper>
      <FooterCopyrightLogo>
        <Logo logo={logo} />
        <FooterCopyrightLocalization onClick={openLanguageModal}>
          <Image width={16} alt="pin" height={16} src={'/assets/images/pin.svg'} />
          <FooterCopyrightLocalizationText color={colors.white}>
            {localization}
          </FooterCopyrightLocalizationText>
        </FooterCopyrightLocalization>
      </FooterCopyrightLogo>
      <FooterCopyrightSocial>
        {socials.map((social) => (
          <Link key={social._uid} href={social.url}>
            <a>
              <Image width={22} height={24} src={social.icon.filename} alt={social.name} />
            </a>
          </Link>
        ))}
      </FooterCopyrightSocial>
    </FooterCopyrightWrapper>
  );
};
