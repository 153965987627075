import styled from 'styled-components';

import { THeaderMobileStyled } from '../Header.types';

export const HeaderMobileWrapper = styled.div<THeaderMobileStyled>`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: flex-start;
  padding: 10rem 2rem;
  position: absolute;
  text-align: left;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.3s ease-in-out;
  top: 0;
  width: 100%;
  right: 0;
  z-index: ${({ theme }) => theme.customZIndex.negative};
`;
