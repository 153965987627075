import styled from 'styled-components';

import { Nav1 } from '../../Typography/Typography.style';

export const HeaderNavWrapper = styled.nav`
  display: flex;
  align-items: center;
  flex-direction: column;

  ${({ theme }) => theme.mediaQuery.xl} {
    flex-direction: row;
  }
`;

export const HeaderNavItem = styled(Nav1)`
  display: block;
  margin: 1.4rem 0;

  ${({ theme }) => theme.mediaQuery.xl} {
    margin: 0 1.2rem;
  }
`;

export const HeaderNavItems = styled.div`
  display: flex;
  gap: 15px;
`;
