import styled from 'styled-components';

import { Body1, Header3 } from '../../../components/Typography/Typography.style';

export const FooterHeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5rem 0;

  ${({ theme }) => theme.mediaQuery.md} {
    border-bottom: 1px solid ${({ theme }) => theme.colors.midGray};
  }

  ${({ theme }) => theme.mediaQuery.xl} {
    padding: 7rem 0;
  }
`;

export const FooterHeroTitle = styled(Header3)`
  max-width: 74.2rem;
`;

export const FooterHeroDescription = styled(Body1)`
  margin-bottom: 3rem;
`;
