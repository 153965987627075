import styled from 'styled-components';

import { THeaderMobileStyled } from '../HeaderV2.types';

export const HeaderMobileWrapper = styled.div<THeaderMobileStyled>`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  height: calc(100dvh - 8rem + 1px);
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  text-align: left;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.3s ease-in-out;
  top: calc(8rem - 1px);
  width: 100%;
  right: 0;
  z-index: ${({ theme }) => theme.customZIndex.negative};
  padding: 2rem 3rem 2rem 2rem;
`;

export const HeaderMobileItemWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderMobileItemLink = styled.a``;

export const HeaderMobileItem = styled.div`
  p {
    font-size: ${({ theme }) => theme.fonts.size.mobile.xs};
    font-weight: ${({ theme }) => theme.fonts.weight.regular};
    line-height: ${({ theme }) => theme.fonts.lineHeight.mobile.s};
    font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
    color: ${({ color, theme }) => color || theme.colors.black};
  }
`;

export const HeaderMobileDownloadButtonWrapper = styled.div`
  width: 100%;

  & button {
    font-size: ${({ theme }) => theme.fonts.size.mobile.xs};
    font-weight: ${({ theme }) => theme.fonts.weight.regular};
    line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.xxxs};
    color: ${({ theme }) => theme.colors.black};
    text-align: center;
    white-space: nowrap;

    padding: 1.55rem 2.2rem;
    min-width: unset;
    width: 100%;

    :focus {
      border: 1px solid ${({ theme }) => theme.colors.black};
    }

    :hover {
      background-color: transparent;
      color: ${({ theme }) => theme.colors.black};
    }
  }
`;

export const HeaderV2Divider = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
`;
