import { StoryblokComponent } from '@storyblok/react';
import React, { useEffect, useState } from 'react';

import { useBasketStatusContext } from 'apps/urban/context/basket';
import { useLoginProviderContext } from 'apps/urban/context/login';
import { useScrollDistanceWatcher } from 'apps/urban/hooks/useScrollDistanceWatcher';
import { useRouter } from 'next/router';

import { ReactComponent as ArrowLeft } from '../../public/assets/images/arrow-left.svg';
import {
  HeaderWrapper,
  HeaderNavMobile,
  HeaderNavDesktop,
  HeaderNavBurgerMobile,
  HeaderLogoSwitchWrapper,
  HeaderSelectedMobileNavigationWrapper,
  HeaderSelectedMobileNavigationTitle,
} from './HeaderV2.style';
import { THeaderProps } from './HeaderV2.types';
import { HeaderV2Burger } from './HeaderV2Burger/HeaderV2Burger';
import { HeaderV2Logo } from './HeaderV2Logo/HeaderV2Logo';
import { HeaderV2Mobile } from './HeaderV2Mobile/HeaderV2Mobile';
import { HeaderV2Nav } from './HeaderV2Nav/HeaderV2Nav';
import { transformNavLoginData } from './util/transform';

// values in pixels; when scrolled past it, the navbar bg will become non-transparent.
const DYNAMIC_BG_AFTER = 100;

export const HeaderV2 = ({
  logo,
  logoLight,
  navigation,
  navigationLogin,
  basketButtonText,
  dropdownFooter,
  myAccountLinkText,
  downloadAppLink,
  downloadAppLinkTitle,
  keepSolidBackground,
}: THeaderProps) => {
  const router = useRouter();

  const [open, setOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const isLoggedIn = useLoginProviderContext();
  const basketContext = useBasketStatusContext();
  const [selectedMobileNavigationId, setSelectedMobileNavigationId] = useState<string | null>(null);

  const passedScroll = useScrollDistanceWatcher(DYNAMIC_BG_AFTER);
  const hasBackground = passedScroll || keepSolidBackground;
  const transformedData = transformNavLoginData(isLoggedIn, navigationLogin, myAccountLinkText);
  const selectedMobileNavigation = navigation.find(
    (navigationItem) => navigationItem._uid === selectedMobileNavigationId
  );

  useEffect(() => {
    // Close all dropdowns when the route changes
    const handleRouteChange = () => {
      const dropdownParents = document.querySelectorAll('.header-v2-nav-item-link-wrapper');

      for (const el of dropdownParents) {
        el.classList.add('disable-hover');
      }

      // Remove the class after a short delay to re-enable the hover effect
      setTimeout(() => {
        for (const el of dropdownParents) {
          el.classList.remove('disable-hover');
        }
      }, 500);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    // unsubscribe as usual
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  return (
    <HeaderWrapper hasBackground={hasBackground || open} className="header-v2">
      <HeaderNavDesktop>
        <HeaderLogoSwitchWrapper>
          <HeaderV2Logo logo={hasBackground || dropdownOpen ? logo : logoLight} />
        </HeaderLogoSwitchWrapper>
        <HeaderV2Nav
          navigation={navigation}
          setOpen={setOpen}
          setDropdownOpen={setDropdownOpen}
          enableDropdown
          dropdownFooter={dropdownFooter?.[0]}
          hasBackground={hasBackground || dropdownOpen}
        />
        <HeaderV2Nav
          basketData={basketContext?.data}
          basketButtonText={basketButtonText}
          showBasket
          navigation={transformedData}
          setOpen={setOpen}
          setDropdownOpen={setDropdownOpen}
          hasBackground={hasBackground || dropdownOpen}
        />
      </HeaderNavDesktop>
      <HeaderNavMobile>
        {selectedMobileNavigation ? (
          <HeaderSelectedMobileNavigationWrapper>
            <ArrowLeft onClick={() => setSelectedMobileNavigationId(null)} />
            <HeaderSelectedMobileNavigationTitle>
              <StoryblokComponent blok={selectedMobileNavigation.title[0]} />
            </HeaderSelectedMobileNavigationTitle>
          </HeaderSelectedMobileNavigationWrapper>
        ) : (
          <HeaderLogoSwitchWrapper>
            <HeaderV2Logo logo={hasBackground || open ? logo : logoLight} />
          </HeaderLogoSwitchWrapper>
        )}
        <HeaderNavBurgerMobile>
          <HeaderV2Nav
            basketData={basketContext?.data}
            showBasket
            showInitials={isLoggedIn}
            navigation={[]}
          />
          <HeaderV2Burger
            hasBackground={hasBackground}
            open={open}
            setOpen={setOpen}
            onClick={() => setSelectedMobileNavigationId(null)}
          />
        </HeaderNavBurgerMobile>
      </HeaderNavMobile>
      <HeaderV2Mobile
        open={open}
        setSelectedMobileNavigationId={setSelectedMobileNavigationId}
        selectedMobileNavigation={selectedMobileNavigation}
        basketData={basketContext?.data}
        basketButtonText={basketButtonText}
        navigation={navigation}
        navigationLogin={isLoggedIn ? [] : navigationLogin}
        setOpen={setOpen}
        downloadAppLink={downloadAppLink}
        downloadAppLinkTitle={downloadAppLinkTitle}
      />
    </HeaderWrapper>
  );
};
