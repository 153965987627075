import styled from 'styled-components';

import { Header4Css, SecondaryText, Header2 } from '../../Typography/Typography.style';

export const HeaderLanguageModalButton = styled.button`
  position: relative;
  width: 2.4rem;
  height: 1.7rem;
  background: none;
  border: 0;
  cursor: pointer;
  padding: 0;
  margin-top: 0.3rem;
`;

export const HeaderLanguageSwitchHeading = styled(Header2)`
  margin-bottom: 3rem;

  ${({ theme }) => theme.mediaQuery.xl} {
    margin-bottom: 6rem;
  }
`;

export const HeaderLanguageSwitchModal = styled.div`
  ${({ theme }) => theme.mediaQuery.xl} {
    padding-bottom: 2.4rem;
  }
`;

export const HeaderLanguageModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3.6rem;
`;

export const HeaderLanguageSwitchButton = styled.button<{ isActive: boolean }>`
  ${Header4Css};
  ${SecondaryText}
  color: ${({ isActive, theme }) => (isActive ? theme.colors.black : theme.colors.silverChalice)};

  background: none;
  border: 0;
  cursor: pointer;
  padding: 0;
  text-transform: capitalize;
`;
