import { StoryblokComponent } from '@storyblok/react';
import React from 'react';

import Link from 'next/link';

import { TNavigation, TNavigationItem } from '../HeaderV2.types';
import { HeaderV2Basket } from '../HeaderV2Basket/HeaderV2Basket';
import { HeaderV2Dropdown } from '../HeaderV2Dropdown/HeaderV2Dropdown';
import { HeaderV2Initials } from '../HeaderV2Initials/HeaderV2Initials';
import {
  HeaderNavWrapper,
  HeaderNavItem,
  HeaderNavItems,
  HeaderNavItemLink,
  HeaderNavItemLinkWrapper,
  HeaderNavItemLinkContainer,
} from './HeaderV2Nav.style';

const navItemHasColumns = (navigationItem: TNavigationItem) => !!navigationItem.columns?.length;

export const HeaderV2Nav = ({
  navigation,
  setOpen,
  setDropdownOpen,
  showBasket,
  showInitials,
  basketData,
  basketButtonText,
  enableDropdown,
  dropdownFooter,
  hasBackground,
}: TNavigation) => {
  const handleNavLinkHover = (navigationItem: TNavigationItem, isDropdownOpen: boolean) => {
    if (setDropdownOpen && enableDropdown && navItemHasColumns(navigationItem)) {
      setDropdownOpen(isDropdownOpen);
    }
  };

  return (
    <HeaderNavWrapper>
      {navigation.map((navigationItem) => (
        <HeaderNavItemLinkContainer key={navigationItem._uid}>
          <HeaderNavItemLinkWrapper
            className={
              enableDropdown && navItemHasColumns(navigationItem)
                ? 'header-v2-nav-item-link-wrapper'
                : undefined
            }
            onMouseEnter={() => handleNavLinkHover(navigationItem, true)}
            onMouseLeave={() => handleNavLinkHover(navigationItem, false)}
          >
            <Link
              prefetch={false}
              href={
                navigationItem.item.cached_url.startsWith('http')
                  ? navigationItem.item.cached_url
                  : `/${navigationItem.item.cached_url}`
              }
              passHref
            >
              <HeaderNavItemLink onClick={() => setOpen(false)}>
                <HeaderNavItem hasBackground={hasBackground} className="header-v2-nav-item">
                  <StoryblokComponent blok={navigationItem.title[0]} />
                </HeaderNavItem>
              </HeaderNavItemLink>
            </Link>
            {enableDropdown && !!navigationItem.columns?.length && (
              <HeaderV2Dropdown navigationItem={navigationItem} footer={dropdownFooter} />
            )}
          </HeaderNavItemLinkWrapper>
        </HeaderNavItemLinkContainer>
      ))}
      {(showBasket || showInitials) && (
        <HeaderNavItems>
          {showBasket && (
            <HeaderV2Basket basketData={basketData} basketButtonText={basketButtonText} />
          )}
          {showInitials && <HeaderV2Initials />}
        </HeaderNavItems>
      )}
    </HeaderNavWrapper>
  );
};
