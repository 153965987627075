import { useEffect, useState } from 'react';

/**
 * Accepts a `distance` value and checks whether a user has scrolled past this value.
 * */
export function useScrollDistanceWatcher(distance: number): boolean {
  const [passed, setPassed] = useState(false);

  useEffect(() => {
    const listener = () => {
      if (window.scrollY > distance) {
        setPassed(true);
      } else {
        setPassed(false);
      }
    };

    window.addEventListener('scroll', listener);

    return () => window.removeEventListener('scroll', listener);
  }, []);

  return passed;
}
