import React from 'react';

import { colors } from '../../../styles/constants';
import { Button } from '../../Button/Button';
import { TFooterHeroProps } from '../Footer.types';
import { FooterHeroWrapper, FooterHeroTitle, FooterHeroDescription } from './FooterHero.style';

export const FooterHero = ({ title, description, buttonUrl, buttonText }: TFooterHeroProps) => (
  <FooterHeroWrapper>
    <FooterHeroTitle align="center" color={colors.white}>
      {title}
    </FooterHeroTitle>
    <FooterHeroDescription align="center" color={colors.white}>
      {description}
    </FooterHeroDescription>
    <Button variant="outlinedLight" text={buttonText} url={buttonUrl} />
  </FooterHeroWrapper>
);
