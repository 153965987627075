import React from 'react';

import { NextLink } from 'apps/urban/components/NextLink/NextLink';

import { colors } from '../../../../styles/constants';
import { Body3 } from '../../../Typography/Typography.style';
import { TNavigation } from '../../Footer.types';
import {
  FooterNavigationWrapper,
  FooterNavigationNav,
  FooterNavigationNavItem,
} from './FooterNavigation.style';

export const FooterNavigation = ({ title, navigation }: TNavigation) => {
  return (
    <FooterNavigationWrapper>
      <Body3 color={colors.white}>{title}</Body3>
      <FooterNavigationNav>
        {navigation.map((item) => (
          <NextLink
            key={item._uid}
            href={
              item.item.cached_url.startsWith('http')
                ? item.item.cached_url
                : `/${item.item.cached_url}`
            }
          >
            <FooterNavigationNavItem color={colors.white}>{item.title}</FooterNavigationNavItem>
          </NextLink>
        ))}
      </FooterNavigationNav>
    </FooterNavigationWrapper>
  );
};
