import React, { forwardRef, LegacyRef } from 'react';

import Link from 'next/link';

import { TNavigation, LinkProps } from '../Header.types';
import { HeaderBasket } from '../HeaderBasket/HeaderBasket';
import { HeaderInitials } from '../HeaderInitials/HeaderInitials';
import { HeaderNavWrapper, HeaderNavItem, HeaderNavItems } from './HeaderNav.style';

const StatefulLink = forwardRef(function StatefulLink(
  { onClick, href, title }: LinkProps,
  ref: LegacyRef<HTMLAnchorElement>
) {
  return (
    <a href={href} onClick={onClick} ref={ref}>
      <HeaderNavItem>{title}</HeaderNavItem>
    </a>
  );
});

export const HeaderNav = ({
  navigation,
  setOpen,
  showBasket,
  showInitials,
  basketData,
}: TNavigation) => (
  <HeaderNavWrapper>
    {navigation.map((navigationItem) => (
      <Link
        key={navigationItem._uid}
        href={
          navigationItem.item.cached_url.startsWith('http')
            ? navigationItem.item.cached_url
            : `/${navigationItem.item.cached_url}`
        }
        passHref
      >
        <StatefulLink title={navigationItem.title} onClick={() => setOpen(false)} />
      </Link>
    ))}
    {(showBasket || showInitials) && (
      <HeaderNavItems>
        {showBasket && <HeaderBasket basketData={basketData} />}
        {showInitials && <HeaderInitials />}
      </HeaderNavItems>
    )}
  </HeaderNavWrapper>
);
