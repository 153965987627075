import styled from 'styled-components';

import { THeaderMobileStyled } from '../HeaderV2.types';

export const HeaderBurgerWrapper = styled.button<THeaderMobileStyled>`
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 5rem;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 5rem;

  div {
    background: ${({ theme, hasBackground, open }) =>
      hasBackground || open ? theme.colors.black : theme.colors.white};
    border-radius: 1rem;
    height: 0.1rem;
    position: relative;
    transition: all 0.2s linear;
    transform-origin: 1px;
    width: 1.8rem;

    :first-child {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
      margin-bottom: 0.5rem;
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
      transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
      margin-top: 0.5rem;
    }
  }
`;
