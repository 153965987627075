import styled from 'styled-components';

import { Body4 } from '../../../../components/Typography/Typography.style';

export const FooterNavigationWrapper = styled.div`
  display: none;

  ${({ theme }) => theme.mediaQuery.md} {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 35rem;

    &:nth-child(even) {
      margin: 0 8rem;
    }
  }
`;

export const FooterNavigationNav = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
`;

export const FooterNavigationNavItem = styled(Body4)`
  line-height: ${({ theme }) => theme.fonts.lineHeight.mobile.m};
`;
