import { useEffect } from 'react';

/**
 * Disables scroll globally if passed conditions are met.
 * */
export function useDisabledScroll(disabled: boolean): void {
  useEffect(() => {
    if (disabled) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'visible';
  }, [disabled]);
}
