import React from 'react';

import Image from 'next/image';

import { TLocationFlagIconProps } from '../Header.types';
import { HeaderLanguageModalButton } from './HeaderLanguageSwitch.style';

export const HeaderLanguageSwitch = ({
  locationFlagIcon,
  openLanguageModal,
}: TLocationFlagIconProps) => (
  <HeaderLanguageModalButton onClick={openLanguageModal}>
    <Image
      src={locationFlagIcon.filename}
      alt={locationFlagIcon.alt}
      layout="fill"
      objectFit="contain"
    />
  </HeaderLanguageModalButton>
);
