import styled from 'styled-components';

export const HeaderInitialsWrapper = styled.div`
    background-color: black;
    width: 3rem;
    height: 3rem;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Content = styled.span`
  color: white;
  margin: 0 !important;
  font-size: 1.2rem !important;
  text-transform: uppercase;
  line-height: 3.3rem;
`;
