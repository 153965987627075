import React from 'react';

import Image from 'next/image';
import Link from 'next/link';

import { TLogo } from './Logo.types';

export const Logo = ({ logo }: TLogo) => (
  <Link href="/">
    <a>
      <Image width={120} height={24} src={logo.filename} alt={logo.alt} />
    </a>
  </Link>
);
