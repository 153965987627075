import { Api } from '@b2c-web-marketing/shared/storyblok-sdk';

import { BlogarticleItem } from '../types/basic';
import { BlogArticleItemDocument } from '../types/hooks';
import { BlogArticleItemQuery, BlogArticleItemQueryVariables } from '../types/operations';

export const getBlogArticle = async (
  variables: BlogArticleItemQueryVariables
): Promise<BlogarticleItem> => {
  const { data } = await Api.getPageItem<BlogArticleItemQuery, BlogArticleItemQueryVariables>(
    BlogArticleItemDocument,
    variables
  );
  return data.BlogarticleItem as BlogarticleItem;
};
