import styled from 'styled-components';

import { Nav1 } from '../../../components/Typography/Typography.style';
import { THeaderNavItemLinkWrapperStyled, THeaderNavItemStyled } from './HeaderV2Nav.types';

export const HeaderNavWrapper = styled.nav`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;

  ${({ theme }) => theme.mediaQuery.xl} {
    flex-direction: row;
  }
`;

export const HeaderNavItemLinkContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const HeaderNavItemLinkWrapper = styled.div<THeaderNavItemLinkWrapperStyled>`
  height: 50%;
  display: flex;
  align-items: center;

  :hover:not(.disable-hover) {
    height: 100%;
    .header-v2-dropdown {
      opacity: 1;
      transform: scaleY(1);
    }
  }
`;

export const HeaderNavItemLink = styled.a``;

export const HeaderNavItem = styled(Nav1)<THeaderNavItemStyled>`
  display: block;
  margin: 1.4rem 0;

  p {
    font-size: ${({ theme }) => theme.fonts.size.desktop.xxs};
    font-weight: ${({ theme }) => theme.fonts.weight.regular};
    line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.xxs};
    color: ${({ theme, hasBackground }) =>
      hasBackground ? theme.colors.black : theme.colors.white};
  }

  color: ${({ theme, hasBackground }) => (hasBackground ? theme.colors.black : theme.colors.white)};

  ${({ theme }) => theme.mediaQuery.xl} {
    margin: 0 1.2rem;
  }

  :hover {
    text-decoration: underline;
  }
`;

export const HeaderNavItems = styled.div`
  display: flex;
  gap: 15px;
`;
