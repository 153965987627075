import React, { useState, useEffect } from 'react';

import axios from 'axios';
import Cookies from 'js-cookie';
import getConfig from 'next/config';
import Link from 'next/link';

import { HeaderInitialsWrapper, Content } from './HeaderInitials.style';

const getFirstLetter = (str) => {
  return str
    .split(' ')
    .map(function (item) {
      return item[0];
    })
    .join('');
};

export const HeaderInitials = () => {
  const [userData, setUserData] = useState('');
  const config = getConfig();

  const getUserInitials = async (userId) => {
    try {
      const result = await axios(
        `${config?.publicRuntimeConfig?.NEXT_PUBLIC_API_BASE}/me/home?include=me`,
        {
          headers: {
            'x-application-identifier':
              config?.publicRuntimeConfig?.NEXT_PUBLIC_JS_API_APPLICATION_KEY,
            'x-user-identifier': userId,
          },
        }
      );
      const data = result?.data?.me?.name;
      setUserData(data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const authCookie = Cookies.get('auth');
    if (authCookie) {
      getUserInitials(authCookie);
    }
  }, []);

  return userData ? (
    <HeaderInitialsWrapper>
      <Link href="/account/bookings">
        <a>
          <Content>{getFirstLetter(userData)}</Content>
        </a>
      </Link>
    </HeaderInitialsWrapper>
  ) : null;
};
