import React from 'react';

import { THeaderBurgerProps } from '../Header.types';
import { HeaderBurgerWrapper } from './HeaderBurger.style';

export const HeaderBurger = ({ open, setOpen }: THeaderBurgerProps) => (
  <HeaderBurgerWrapper open={open} onClick={() => setOpen(!open)}>
    <div />
    <div />
    <div />
  </HeaderBurgerWrapper>
);
