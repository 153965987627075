import styled from 'styled-components';

import { TFooterWrapperStyled } from './FooterV2.types';

export const FooterWrapper = styled.footer<TFooterWrapperStyled>`
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ? backgroundColor : theme.colors.lightGray};
  padding: 0 2.4rem;
`;

export const FooterContainer = styled.div`
  margin: 0 auto;
  max-width: ${({ theme }) => theme.layout.container};
  width: 100%;
`;
