import React from 'react';

import { Accordion } from '../../Accordion/Accordion';
import { TNavigationsProps } from '../Footer.types';
import { FooterNavigation } from './FooterNavigation/FooterNavigation';
import { FooterNavigationsWrapper, FooterAccordionMobile } from './FooterNavigations.style';

export const FooterNavigations = ({ navigations }: TNavigationsProps) => (
  <FooterNavigationsWrapper>
    <>
      {navigations.map((navigation) => (
        <FooterNavigation key={navigation._uid} {...navigation} />
      ))}
      <FooterAccordionMobile>
        <Accordion data={navigations} context="footer" />
      </FooterAccordionMobile>
    </>
  </FooterNavigationsWrapper>
);
