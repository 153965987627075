import React from 'react';

import Image from 'next/image';
import Link from 'next/link';

import { TLogoProps } from '../HeaderV2.types';

export const HeaderV2Logo = ({ logo }: TLogoProps) => (
  <Link href="/" prefetch={false}>
    <a>
      <Image width={120} height={23} src={logo.filename} alt={logo.alt} priority />
    </a>
  </Link>
);
