import React, { useEffect, useState } from 'react';
import { MouseEvent } from 'react';

import Cookies from 'js-cookie';

import { createMarkup } from '../../utils/createMarkup/createMarkup';
import { Button } from '../Button/Button';
import {
  CookieBannerWrapper,
  CookieBannerContent,
  CookieBannerTextContainer,
  CookieBannerTitle,
  CookieBannerDescription,
  CookieBannerButtonContainer,
} from './CookieBanner.style';
import { TCookieBannerProps } from './CookieBanner.types';

const CONSENT_COOKIE_KEY = 'CookieConsent';
const CONSENT_COOKIE_EXPIRE_DATE = 365;

export const CookieBanner = ({ title, description, buttonText }: TCookieBannerProps) => {
  const [isConsentCookieTrue, setIsConsentCookieTrue] = useState(true);

  useEffect(() => {
    const consentCookieIsTrue = Cookies.get(CONSENT_COOKIE_KEY) === 'true';
    setIsConsentCookieTrue(consentCookieIsTrue);
  }, []);

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!isConsentCookieTrue) {
      Cookies.set(CONSENT_COOKIE_KEY, 'true', {
        expires: CONSENT_COOKIE_EXPIRE_DATE,
      });
    }
    setIsConsentCookieTrue(true);
  };

  if (isConsentCookieTrue) {
    return null;
  }

  return (
    <CookieBannerWrapper>
      <CookieBannerContent>
        <CookieBannerTextContainer>
          <CookieBannerTitle>{title}</CookieBannerTitle>
          <CookieBannerDescription dangerouslySetInnerHTML={createMarkup(description)} />
        </CookieBannerTextContainer>
        <CookieBannerButtonContainer>
          <Button text={buttonText} variant="filled" onClickHandler={handleClick} shrink={true} />
        </CookieBannerButtonContainer>
      </CookieBannerContent>
    </CookieBannerWrapper>
  );
};
