import React from 'react';

import { BasketIcon2 } from 'apps/urban/public/assets/images/Basket2';
import Link from 'next/link';

import { Button } from '../../../components/Button/Button';
import { HeaderBasketButtonWrapper, HeaderBasketWrapper } from './HeaderV2Basket.style';
import { HeaderV2BasketProps } from './HeaderV2Basket.types';

const DEFAULT_BASKET_BUTTON_TEXT = 'Book now';
const BASKET_LINK = '/booking-next/basket';
const BOOK_NOW_LINK = '/booking-next';

export const HeaderV2Basket = ({
  basketData,
  basketButtonText,
  showOnMobile,
  fullWidth,
}: HeaderV2BasketProps) => {
  const { serviceCount, hasStartsAt, hasWorker } = basketData || {};

  const displayOneItem = serviceCount === 0 && (hasStartsAt || hasWorker);
  const hasAnyItem = serviceCount > 0 || displayOneItem;

  const numberOfItems = (n: number) => <span className="count">{n}</span>;

  return hasAnyItem && !showOnMobile ? (
    <HeaderBasketWrapper hasItems={!!hasAnyItem}>
      <Link href={BASKET_LINK} prefetch={false}>
        <a className="link">
          {serviceCount > 0 && numberOfItems(serviceCount as number)}
          {displayOneItem && numberOfItems(1)}
          <BasketIcon2 theme="primary" />
        </a>
      </Link>
    </HeaderBasketWrapper>
  ) : (
    <HeaderBasketButtonWrapper showOnMobile={showOnMobile} fullWidth={fullWidth}>
      <Button
        variant="filled"
        text={basketButtonText || DEFAULT_BASKET_BUTTON_TEXT}
        url={BOOK_NOW_LINK}
      />
    </HeaderBasketButtonWrapper>
  );
};
