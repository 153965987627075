import React from 'react';

export const BasketIcon2 = ({ theme }) => {
  const mode = theme === 'primary' ? '#FFFFFF' : '#000000';

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-button-ico"
    >
      <g clipPath="url(#clip0_49_978)">
        <g clipPath="url(#clip1_49_978)">
          <path
            data-testid="cart-icon"
            d="M0.5625 0.5625H3.9375V10.6875H15.1875L17.4375 2.8125H7.3125"
            stroke={mode}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.5 17.4375C5.43198 17.4375 6.1875 16.682 6.1875 15.75C6.1875 14.818 5.43198 14.0625 4.5 14.0625C3.56802 14.0625 2.8125 14.818 2.8125 15.75C2.8125 16.682 3.56802 17.4375 4.5 17.4375Z"
            stroke={mode}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.625 17.4375C15.557 17.4375 16.3125 16.682 16.3125 15.75C16.3125 14.818 15.557 14.0625 14.625 14.0625C13.693 14.0625 12.9375 14.818 12.9375 15.75C12.9375 16.682 13.693 17.4375 14.625 17.4375Z"
            stroke={mode}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_49_978">
          <rect width="18" height="18" fill="transparent" />
        </clipPath>
        <clipPath id="clip1_49_978">
          <rect width="18" height="18" fill="transparent" />
        </clipPath>
      </defs>
    </svg>
  );
};
