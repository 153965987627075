import { getBlogArticle, getBlogCategory, getPage } from 'apps/urban/api';

import { PageItem } from '../../api/types/basic';

export const handlePageItemLoad = async (slug: string, relations: string): Promise<PageItem> => {
  const pageItem = getPage({ slug, relations });
  return pageItem;
};

export const handleBlogArticleLoad = async (slug: string, relations: string) => {
  const articleItem = getBlogArticle({ slug, relations });
  return articleItem;
};

export const handleBlogCategoryLoad = async (slug: string, relations: string) => {
  const blogCategoryItem = getBlogCategory({ slug, relations });
  return blogCategoryItem;
};
