import { Api } from '@b2c-web-marketing/shared/storyblok-sdk';

import { ContentItem } from '../types/basic';
import { GetContentNodeDocument } from '../types/hooks';
import { GetContentNodeQueryVariables, GetContentNodeQuery } from '../types/operations';

//@ts-ignore
const isContentNode = (data: ContentItem) => {
  return data !== undefined;
};

export const getContentNode = async (
  variables: GetContentNodeQueryVariables
): Promise<ContentItem | null> => {
  const { data } = await Api.getPageItem<GetContentNodeQuery, GetContentNodeQueryVariables>(
    GetContentNodeDocument,
    variables
  );

  //@ts-ignore
  return isContentNode(data?.ContentNode) ? data?.ContentNode : null;
};
