import styled from 'styled-components';

export const CookieBannerWrapper = styled.section`
  background-color: ${({ theme }) => theme.colors.lightGray};
  z-index: ${({ theme }) => theme.customZIndex.cookie};

  position: fixed;
  inset: auto 0 0 0;
`;

export const CookieBannerContent = styled.div`
  max-width: 144rem;
  margin: 0 auto;
  padding: 3.2rem 2.4rem;

  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  ${({ theme }) => theme.mediaQuery.sm} {
    flex-direction: row;
  }
`;

export const CookieBannerTextContainer = styled.div`
  font-family: ${({ theme }) => theme.fonts.fontFamily.primary};
  font-size: ${({ theme }) => theme.fonts.size.mobile.xxs};

  flex: 1;
`;

export const CookieBannerTitle = styled.h3`
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.xxs};

  margin-bottom: 2.4rem;
`;

export const CookieBannerDescription = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.light};
  line-height: ${({ theme }) => theme.fonts.lineHeight.desktop.xs};

  a {
    color: ${({ theme }) => theme.colors.black};
    border-bottom: 1px solid ${({ theme }) => theme.colors.black};
  }

  b {
    font-weight: ${({ theme }) => theme.fonts.weight.medium};
  }

  p:empty {
    height: 2.4rem;
  }
`;

export const CookieBannerButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
