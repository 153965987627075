import { StoryblokComponent } from '@storyblok/react';
import React, { useCallback, useMemo } from 'react';

import Link from 'next/link';
import { render } from 'storyblok-rich-text-react-renderer';

import { Button } from '../../../components/Button/Button';
import { useDisabledScroll } from '../../../hooks/useDisabledScroll';
import { ReactComponent as Chevron } from '../../../public/assets/images/chevron-right.svg';
import { THeaderMobileProps } from '../HeaderV2.types';
import { HeaderV2Basket } from '../HeaderV2Basket/HeaderV2Basket';
import {
  HeaderMobileDownloadButtonWrapper,
  HeaderMobileItem,
  HeaderMobileItemLink,
  HeaderMobileItemWrapper,
  HeaderMobileWrapper,
  HeaderV2Divider,
} from './HeaderV2Mobile.style';

const DEFAULT_VIEW_ALL_TEXT = 'View All';

export const HeaderV2Mobile = ({
  open,
  navigation,
  navigationLogin,
  setOpen,
  setSelectedMobileNavigationId,
  selectedMobileNavigation,
  basketData,
  basketButtonText,
  downloadAppLink,
  downloadAppLinkTitle,
}: THeaderMobileProps) => {
  useDisabledScroll(open);

  const handleLinkClick = useCallback(() => {
    setOpen(false);
    setSelectedMobileNavigationId(null);
  }, [setOpen, setSelectedMobileNavigationId]);

  const content = useMemo(() => {
    if (selectedMobileNavigation) {
      return (
        <>
          {selectedMobileNavigation.columns.map((column) => (
            <Link
              prefetch={false}
              key={column._uid}
              href={
                column.item.cached_url.startsWith('http')
                  ? column.item.cached_url
                  : `/${column.item.cached_url}`
              }
              passHref
            >
              <HeaderMobileItemLink onClick={handleLinkClick}>
                <HeaderMobileItem>
                  <StoryblokComponent blok={column.title[0]} />
                </HeaderMobileItem>
              </HeaderMobileItemLink>
            </Link>
          ))}
          <HeaderV2Divider />
          <Link
            prefetch={false}
            href={
              selectedMobileNavigation.item.cached_url.startsWith('http')
                ? selectedMobileNavigation.item.cached_url
                : `/${selectedMobileNavigation.item.cached_url}`
            }
            passHref
          >
            <HeaderMobileItemLink onClick={handleLinkClick}>
              <HeaderMobileItem>
                {selectedMobileNavigation.mobileViewAllButtonText[0] ? (
                  <StoryblokComponent blok={selectedMobileNavigation.mobileViewAllButtonText[0]} />
                ) : (
                  DEFAULT_VIEW_ALL_TEXT
                )}
              </HeaderMobileItem>
            </HeaderMobileItemLink>
          </Link>
        </>
      );
    }

    return navigation.map((navigationItem) =>
      !!navigationItem.columns?.length ? (
        <HeaderMobileItemWrapper
          key={navigationItem._uid}
          onClick={() => setSelectedMobileNavigationId(navigationItem._uid)}
        >
          <HeaderMobileItem>
            <StoryblokComponent blok={navigationItem.title[0]} />
          </HeaderMobileItem>
          <Chevron />
        </HeaderMobileItemWrapper>
      ) : (
        <Link
          key={navigationItem._uid}
          href={
            navigationItem.item.cached_url.startsWith('http')
              ? navigationItem.item.cached_url
              : `/${navigationItem.item.cached_url}`
          }
          passHref
        >
          <HeaderMobileItemLink onClick={handleLinkClick}>
            <HeaderMobileItem>
              <StoryblokComponent blok={navigationItem.title[0]} />
            </HeaderMobileItem>
          </HeaderMobileItemLink>
        </Link>
      )
    );
  }, [handleLinkClick, navigation, selectedMobileNavigation, setSelectedMobileNavigationId]);

  return (
    <HeaderMobileWrapper open={open}>
      {content}
      <HeaderV2Divider style={{ marginTop: 'auto' }} />
      {navigationLogin.map((navigationItem) => (
        <Link
          key={navigationItem._uid}
          href={
            navigationItem.item.cached_url.startsWith('http')
              ? navigationItem.item.cached_url
              : `/${navigationItem.item.cached_url}`
          }
          passHref
        >
          <HeaderMobileItemLink onClick={handleLinkClick}>
            <HeaderMobileItem>
              <StoryblokComponent blok={navigationItem.title[0]} />
            </HeaderMobileItem>
          </HeaderMobileItemLink>
        </Link>
      ))}
      <HeaderV2Basket
        basketData={basketData}
        basketButtonText={basketButtonText}
        showOnMobile
        fullWidth
      />
      {downloadAppLink && downloadAppLinkTitle[0]?.content && (
        <HeaderMobileDownloadButtonWrapper>
          <Button
            text={render(downloadAppLinkTitle[0]?.content)}
            url={downloadAppLink?.cachedUrl}
            variant="outlinedDark"
          />
        </HeaderMobileDownloadButtonWrapper>
      )}
    </HeaderMobileWrapper>
  );
};
