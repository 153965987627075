import React from 'react';

import { THeaderBurgerProps } from '../HeaderV2.types';
import { HeaderBurgerWrapper } from './HeaderV2Burger.style';

export const HeaderV2Burger = ({ open, setOpen, onClick, hasBackground }: THeaderBurgerProps) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }

    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <HeaderBurgerWrapper open={open} onClick={handleClick} hasBackground={hasBackground}>
      <div />
      <div />
      <div />
    </HeaderBurgerWrapper>
  );
};
