import type { TNavigationItem } from '../Header.types';

const createNavItem = (data: TNavigationItem[], replacement: string) => [
  { ...data[0], title: replacement },
];

export const transformNavLoginData = (
  userLogged: boolean,
  navData: TNavigationItem[],
  locale: string
) => {
  if (!userLogged) {
    return navData;
  }

  return locale === 'en-gb'
    ? createNavItem(navData, 'My Account')
    : createNavItem(navData, 'Mon compte');
};
