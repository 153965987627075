import { Api } from '@b2c-web-marketing/shared/storyblok-sdk';

import { HeaderItem } from '../types/basic';
import { HeaderItemDocument } from '../types/hooks';
import { HeaderItemQuery, HeaderItemQueryVariables } from '../types/operations';

export const getHeader = async (variables: HeaderItemQueryVariables): Promise<HeaderItem> => {
  const { data } = await Api.getHeaderItem<HeaderItemQuery, HeaderItemQueryVariables>(
    HeaderItemDocument,
    variables
  );

  return data.HeaderItem;
};
